@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0; }

body {
  line-height: 1; }

hr {
  display: block;
  width: 100%; }

.efekt, .menu__link, .istasyon-btn, .home-gallery-slider .fresco img, .custom-arrow, .tab-nav .tab-link, .col--pad .fresco img {
  transition: all 0.25s ease-in-out; }

.ortala {
  margin-left: auto;
  margin-right: auto; }

.flex, .header__right, .header--flex, .menu, .custom-arrows, .footer__bottom .container, .vertical-menu__link {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.bdrs {
  border-radius: 0.25rem; }

.dikeyAbsolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.dikeyFlex {
  display: flex;
  align-items: center; }

.bxs, .left-menu {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out; }
  .bxs:Hover, .left-menu:Hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1), 0 8px 18px rgba(0, 0, 0, 0.1); }

.bxsh {
  transition: all 0.2s ease-in-out; }
  .bxsh:Hover {
    box-shadow: 0 10px 22px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1); }

.fz15 {
  font-size: 1.5rem; }

.fz14 {
  font-size: 14rem; }

.fz13 {
  font-size: 1.3rem; }

.fz12 {
  font-size: 1.2rem; }

.fz11 {
  font-size: 1.1rem; }

.fz10 {
  font-size: 1rem; }

.fz9 {
  font-size: 0.9rem; }

.fz85 {
  font-size: 0.85rem; }

.fz8 {
  font-size: 0.8rem; }

.fz7 {
  font-size: 0.7rem; }

.bgcover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.fw600 {
  font-weight: 600; }

.fw700 {
  font-weight: 700; }

.fw800 {
  font-weight: 800; }

.mb15 {
  margin-bottom: 15px; }

.mb30 {
  margin-bottom: 30px; }

.mb50 {
  margin-bottom: 50px; }

.mb60 {
  margin-bottom: 60px; }

.mb75 {
  margin-bottom: 75px; }

.mb100 {
  margin-bottom: 100px; }

.mb150 {
  margin-bottom: 150px; }

.mt15 {
  margin-top: 15px; }

.mt30 {
  margin-top: 30px; }

.mt50 {
  margin-top: 50px; }

.mt60 {
  margin-top: 60px; }

.mt75 {
  margin-top: 75px; }

.mt100 {
  margin-top: 100px; }

.mt150 {
  margin-top: 150px; }

.visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none; }

.visible-scrollbar::-webkit-scrollbar:vertical {
  width: 2px; }

.visible-scrollbar::-webkit-scrollbar:horizontal {
  height: 2px; }

.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.visible-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1); }

.scrollbar::-webkit-scrollbar {
  width: 4px; }

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; }

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px; }

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #F07400; }

.scrollbar-yatay::-webkit-scrollbar {
  height: 4px; }

.scrollbar-yatay::-webkit-scrollbar-track {
  background: #f1f1f1; }

.scrollbar-yatay::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px; }

.scrollbar-yatay::-webkit-scrollbar-thumb:hover {
  background: #F07400; }

.text-center {
  text-align: center; }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*:after,
*:before {
  box-sizing: inherit; }

body {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.5;
  color: #fff;
  background: #404F5C; }

img {
  max-width: 100%;
  vertical-align: middle; }

button,
input,
select,
textarea {
  font-family: "Source Sans Pro", sans-serif; }

.pos-rel {
  position: relative; }

.slick-slide {
  outline: transparent !important; }
  .slick-slide a {
    outline: transparent !important; }

.noscroll {
  overflow: hidden; }

.tab-panel {
  display: none; }

.tab-active {
  display: block;
  animation: fadeIn 0.5s; }

.tabcontent {
  animation: fadeIn 0.5s; }

.fadeIn {
  animation: fadeIn 0.5s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border: 1px solid #212b34;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control[type=file] {
  overflow: hidden; }

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.form-control::-webkit-date-and-time-value {
  height: 1.5em; }

.form-control::-webkit-input-placeholder {
  color: #f4f4f4;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #f4f4f4;
  opacity: 1; }

.form-control::placeholder {
  color: #f4f4f4;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3; }

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3; }

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
::after,
::before {
  box-sizing: inherit; }

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  -ms-flex-wrap: wrap; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  min-height: 1px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  -ms-flex: 0 0 auto; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  -ms-flex: 0 0 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  -ms-flex: 0 0 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  -ms-flex: 0 0 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  -ms-flex: 0 0 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  -ms-flex: 0 0 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  -ms-flex: 0 0 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  -ms-flex: 0 0 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  -ms-flex: 0 0 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  -ms-flex: 0 0 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  -ms-flex: 0 0 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  -ms-flex: 0 0 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  -ms-flex: 0 0 100%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    -ms-flex: 0 0 auto; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    -ms-flex: 0 0 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    -ms-flex: 0 0 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    -ms-flex: 0 0 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    -ms-flex: 0 0 100%; } }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.flex-wrap {
  flex-wrap: wrap; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.justify-content-start {
  justify-content: flex-start !important;
  -ms-flex-pack: start !important; }

.justify-content-end {
  justify-content: flex-end !important;
  -ms-flex-pack: end !important; }

.justify-content-center {
  justify-content: center !important;
  -ms-flex-pack: center !important; }

.justify-content-between {
  justify-content: space-between !important;
  -ms-flex-pack: justify !important; }

.justify-content-around {
  justify-content: space-around !important;
  -ms-flex-pack: distribute !important; }

.align-items-start {
  align-items: flex-start !important;
  -ms-flex-align: start !important; }

.align-items-end {
  align-items: flex-end !important;
  -ms-flex-align: end !important; }

.align-items-center {
  align-items: center !important;
  -ms-flex-align: center !important; }

.m-0 {
  margin: 0 !important; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-times:before {
  content: "\f00d"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-heart:before {
  content: "\f004"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-phone:before {
  content: "\f095"; }

.fa-map:before {
  content: "\f279"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-search:before {
  content: "\f002"; }

.fa-user:before {
  content: "\f007"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-th:before {
  content: "\f00a"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-comment:before {
  content: "\f075"; }

.fa-edit:before {
  content: "\f044"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-minus:before {
  content: "\f068"; }

.fa-plus:before {
  content: "\f067"; }

.fa-clock:before {
  content: "\f017"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-up:before {
  content: "\f0d8"; }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../css/webfonts/fa-brands-400.eot");
  src: url("../css/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-brands-400.woff2") format("woff2"), url("../css/webfonts/fa-brands-400.woff") format("woff"), url("../css/webfonts/fa-brands-400.ttf") format("truetype"), url("../css/webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../css/webfonts/fa-regular-400.eot");
  src: url("../css/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-regular-400.woff2") format("woff2"), url("../css/webfonts/fa-regular-400.woff") format("woff"), url("../css/webfonts/fa-regular-400.ttf") format("truetype"), url("../css/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../css/webfonts/fa-solid-900.eot");
  src: url("../css/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-solid-900.woff2") format("woff2"), url("../css/webfonts/fa-solid-900.woff") format("woff"), url("../css/webfonts/fa-solid-900.ttf") format("truetype"), url("../css/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

/*
 * Note that this is toastr v2.1.3, the "latest" version in url has no more maintenance,
 * please go to https://cdnjs.com/libraries/toastr.js and pick a certain version you want to use,
 * make sure you copy the url from the website since the url may change between versions.
 * */
.toast-title {
  font-weight: 700; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }

.toast-message a, .toast-message label {
  color: #FFF; }

.toast-message a:hover {
  color: #CCC;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -.3em;
  top: -.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1; }

.toast-close-button:focus, .toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

.rtl .toast-close-button {
  left: -.3em;
  float: left;
  right: .3em; }

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none; }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none; }

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: .8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center; }

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

#toast-container > .toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important; }

#toast-container > .toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important; }

#toast-container > .toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important; }

#toast-container > .toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important; }

#toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

#toast-container.toast-bottom-full-width > div, #toast-container.toast-top-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #51A351; }

.toast-error {
  background-color: #BD362F; }

.toast-info {
  background-color: #2F96B4; }

.toast-warning {
  background-color: #F89406; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px; }
  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em; }
  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px; }
  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em; }
  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px; } }

.title {
  font-size: 2rem;
  font-weight: 600;
  display: inline-block;
  font-family: "Oswald", sans-serif; }
  .title--medium {
    font-size: 1.7rem; }
  .title--smaller {
    font-size: 1.4rem; }
  .title--blue {
    color: #fff; }

@media (max-width: 1200px) {
  .title {
    font-size: 2.2rem; }
    .title--medium {
      font-size: 1.7rem; }
    .title--smaller {
      font-size: 1.2rem; } }

@media (max-width: 992px) {
  .title {
    font-size: 2rem; }
    .title--medium {
      font-size: 1.5rem; }
    .title--smaller {
      font-size: 1.1rem; } }

@media (max-width: 768px) {
  .title {
    font-size: 1.7rem; }
    .title--medium {
      font-size: 1.3rem; }
    .title--smaller {
      font-size: 1.1rem; } }

@media (max-width: 576px) {
  .title {
    font-size: 1.3rem; }
    .title--medium {
      font-size: 1.1rem; }
    .title--smaller {
      font-size: 1rem; } }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
  border-bottom: 4px solid transparent;
  padding: 0.475rem 1.4rem;
  font-size: 1rem;
  border-radius: 0;
  font-family: "Oswald", sans-serif;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529; }

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65; }

.btn-primary {
  color: #006DB6;
  background-color: #F8B427;
  border-color: #D38F00; }
  .btn-primary:hover {
    color: #fff;
    background-color: #D38F00;
    border-color: #F8B427; }

.btn-light {
  color: #006DB6;
  background: #fff;
  border-color: #005188; }
  .btn-light:hover {
    color: #006DB6;
    background-color: #F8B427;
    border-color: #D38F00; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.btn-mobil,
.mobil {
  display: none; }

.arama-bg,
.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.arka-active {
  display: block; }

.header {
  position: relative;
  z-index: 5; }
  .header .container {
    position: relative; }
  .header__right {
    justify-content: flex-end;
    width: calc(100% - 200px); }

.header--flex {
  height: 84px;
  border-bottom: 5px solid #005188;
  background: #006DB6; }

.logo {
  padding: 10px 15px;
  width: 171px; }

.menu {
  font-weight: 500;
  font-family: "Oswald", sans-serif; }
  .menu__item:hover > a {
    color: #F8B427; }
  .menu__item:hover .mega-menu {
    display: flex; }
  .menu__link {
    position: relative;
    display: block;
    padding: 0 25px;
    height: 84px;
    color: #fff;
    text-decoration: none;
    line-height: 84px; }
    .menu__link:after {
      transition: height 0.25s ease-in-out;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: #F8B427;
      content: ''; }
    .menu__link.active, .menu__link:hover {
      background: rgba(0, 0, 0, 0.1); }
      .menu__link.active:after, .menu__link:hover:after {
        height: 5px; }

.mega-menu {
  animation: fadeIn .5s;
  background: #004b7e;
  border-bottom: 4px solid #F8B427;
  position: absolute;
  left: 15px;
  right: 15px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  z-index: 20; }

.dropdown {
  padding: 5px 15px;
  width: 23%; }
  .dropdown__title {
    color: #F8B427;
    font-size: 1.1rem;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    margin-bottom: 10px; }
  .dropdown li {
    display: block; }
    .dropdown li:last-child {
      border: none; }
  .dropdown a {
    text-decoration: none;
    display: block;
    padding: 5px;
    font-size: .9rem;
    font-family: "Source Sans Pro", sans-serif;
    color: #fff;
    text-decoration: none;
    transition: color 0.25s ease-in-out; }
    .dropdown a:hover {
      color: #F8B427; }

.istasyon-btn {
  position: relative;
  padding: 0 25px;
  height: 84px;
  border-bottom: 5px solid #D38F00;
  background: #F8B427;
  color: #006DB6;
  text-decoration: none;
  font-weight: 500;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  line-height: 84px; }
  .istasyon-btn:hover {
    border-color: #F8B427;
    background: #005188;
    color: #F8B427; }

.btn--icon svg {
  display: inline-block;
  margin-right: 8px; }

.home-slider {
  position: relative;
  z-index: 1;
  margin: -84px auto 0; }
  .home-slider .item img {
    width: 100%;
    height: 670px;
    object-fit: cover; }
  .home-slider .slick-dots {
    bottom: 100px; }

.slick-dots li {
  width: 10px; }
  .slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 0;
    background: #fff;
    transition: background 0.25s ease-in-out; }
    .slick-dots li button:before {
      display: none; }
    .slick-dots li button:hover {
      background: rgba(255, 255, 255, 0.4); }

.slick-dots .slick-active button {
  background: #F8B427; }

.home-gallery-slider .item {
  margin: 5px 0; }

.home-gallery-slider .slick-dots {
  text-align: left;
  bottom: -35px;
  padding-left: 5px; }
  .home-gallery-slider .slick-dots li button {
    background: #005188; }
    .home-gallery-slider .slick-dots li button:hover {
      background: #006DB6; }
  .home-gallery-slider .slick-dots .slick-active button {
    background: #F8B427; }

.home-gallery-slider .fresco {
  margin-right: 2px;
  margin-bottom: 2px;
  display: block; }
  .home-gallery-slider .fresco img {
    position: relative; }
  .home-gallery-slider .fresco:hover img {
    cursor: zoom-in;
    z-index: 3;
    box-shadow: 0 0 0 2px #F8B427; }

.slider-detail {
  position: absolute;
  top: 220px;
  text-align: center;
  width: 100%; }
  .slider-detail__title {
    color: #fff;
    font-size: 3rem;
    font-family: "Oswald", sans-serif;
    font-weight: 600; }
  .slider-detail__text {
    color: #fff;
    max-width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.4rem;
    font-weight: 300; }

.divider {
  display: block;
  margin: 15px auto;
  width: 9px;
  height: 9px;
  background: #F8B427;
  position: relative; }
  .divider--left {
    margin-left: 50px;
    margin-right: auto; }
  .divider:before {
    content: '';
    position: absolute;
    top: 4px;
    height: 1px;
    background: #F8B427;
    left: -52px;
    width: 45px; }
  .divider:after {
    content: '';
    position: absolute;
    top: 4px;
    height: 1px;
    background: #F8B427;
    right: -52px;
    width: 45px; }

.stations {
  background: #006DB6;
  margin-top: -120px;
  position: relative;
  z-index: 2;
  padding: 15px 15px 0; }
  .stations--page {
    margin-top: 0; }
  .stations__top {
    position: absolute;
    right: 0;
    top: -40px; }
  .stations__img {
    height: 100%; }
    .stations__img img {
      height: 100%;
      object-fit: cover;
      width: 100%; }
  .stations__info {
    color: #fff; }

.custom-arrows {
  width: 86px; }

.custom-arrow {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #F8B427;
  color: #006DB6; }
  .custom-arrow svg {
    width: 20px;
    height: 20px; }
  .custom-arrow:hover {
    background: #005188;
    color: #F8B427; }

.title-divider {
  display: block;
  width: 60px;
  height: 2px;
  margin: 20px 0;
  background: #F8B427; }
  .title-divider--left {
    margin-right: 0; }

.btn--icon--right svg {
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-left: 6px; }

.tab-nav--stations {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 3; }

.tab-nav .tab-link {
  display: inline-block;
  margin-right: 5px;
  background: #f9f9f9;
  padding: 7px 15px;
  border-bottom: 3px solid #dfdfdf;
  text-align: center;
  color: #006DB6;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  text-decoration: none; }
  .tab-nav .tab-link.active, .tab-nav .tab-link:hover {
    background: #F8B427;
    border-color: #D38F00; }

.home-features {
  margin: 70px 0; }

.feature {
  text-align: center; }
  .feature__img {
    max-width: 80%;
    display: block;
    margin: 0 auto -60px;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25); }
  .feature__text {
    background: linear-gradient(100.48deg, #F8B427 0%, #D38F00 100%);
    padding: 80px 30px 30px;
    color: #fff;
    display: block;
    font-size: 1.2rem;
    font-weight: 600; }
  .feature--blue .feature__text {
    background: linear-gradient(100.64deg, #006DB6 0%, #005188 98.67%); }

.text {
  display: block;
  line-height: 1.8; }
  .text--big {
    font-size: 1.3rem;
    color: #fff; }

.about-section--reverse .row {
  flex-direction: row-reverse; }

.about-section .row {
  align-items: center; }

.about-section__img img {
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25); }

.home-gallery {
  position: relative;
  margin-bottom: 120px; }
  .home-gallery .custom-arrows {
    position: absolute;
    bottom: -45px;
    right: 5px; }

.footer__top {
  background: #00508F;
  padding: 15px 0; }

.footer__bottom {
  background: #004371;
  font-size: 0.9rem;
  padding: 15px 0;
  color: rgba(255, 255, 255, 0.7); }

.footer-links a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  margin-left: 15px; }
  .footer-links a:hover {
    text-decoration: underline; }

.hero {
  margin: -84px auto 0;
  padding-top: 140px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: 4px solid #F8B427; }
  .hero--left {
    text-align: left; }
  .hero__title {
    font-size: 2rem;
    font-family: "Oswald", sans-serif;
    margin-bottom: 15px;
    color: #fff; }

.breadcrumb {
  color: rgba(255, 255, 255, 0.82);
  font-size: 0.9rem;
  font-weight: 600; }
  .breadcrumb__link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.82); }
    .breadcrumb__link:hover {
      text-decoration: underline; }
  .breadcrumb__divider {
    display: inline-block;
    margin: 0 6px; }

.row--pad {
  margin-left: -5px;
  margin-right: -5px; }

.col--pad {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px; }
  .col--pad .fresco img {
    position: relative; }
  .col--pad .fresco:hover img {
    cursor: zoom-in;
    z-index: 3;
    box-shadow: 0 0 0 2px #F8B427; }

.station-img {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 50%;
  border: 4px solid #F8B427; }

.map {
  border-top: 4px solid #F8B427; }
  .map iframe {
    vertical-align: middle;
    width: 100%; }

.contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin: 30px auto; }
  .contact-info__item {
    width: 32%;
    padding: 30px;
    background: #006DB6;
    color: #fff; }
  .contact-info__icon {
    text-align: center;
    height: 60px;
    color: #F8B427; }
    .contact-info__icon svg {
      width: 45px;
      height: 45px; }
  .contact-info__text {
    font-size: 16px;
    display: block;
    min-height: 57px; }
  .contact-info__title {
    display: block;
    font-weight: 800;
    font-size: 1.1rem;
    color: #F8B427;
    margin-bottom: 15px;
    font-family: "Oswald", sans-serif; }
  .contact-info a {
    color: #fff;
    text-decoration: none;
    transition: color 0.25s ease-in-out; }
    .contact-info a:hover {
      color: #F8B427; }

.social-footer a {
  font-size: 1.4rem;
  display: inline-block;
  margin: 5px;
  color: #fff;
  text-decoration: none;
  transition: color 0.25s ease-in-out; }
  .social-footer a:hover {
    color: #F8B427; }

.home-gallery-slider .item img {
  height: 300px;
  width: 100%;
  object-fit: cover; }

.gallery-img {
  height: 300px;
  width: 100%;
  object-fit: cover; }

.home-gallery-slider .slick-dots li button {
  background: #fff; }

.about-section__text,
.text,
.title,
.title--blue {
  color: #fff !important;
  background: inherit !important;
  background-color: inherit !important; }
  .about-section__text b,
  .about-section__text p,
  .about-section__text span,
  .about-section__text strong,
  .text b,
  .text p,
  .text span,
  .text strong,
  .title b,
  .title p,
  .title span,
  .title strong,
  .title--blue b,
  .title--blue p,
  .title--blue span,
  .title--blue strong {
    color: #fff !important;
    background: inherit !important;
    background-color: inherit !important; }

.kampanya {
  padding: 15px;
  background: rgba(0, 0, 0, 0.1);
  transition: background 0.25s ease-in-out; }
  .kampanya__title {
    font-size: 1.3rem;
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #fff; }
  .kampanya__text {
    color: rgba(255, 255, 255, 0.8); }
  .kampanya a {
    color: inherit;
    text-decoration: none; }
  .kampanya:hover {
    background: rgba(0, 0, 0, 0.2); }

.content-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 30px; }
  .content-page .content {
    width: calc(100% - 310px); }

.left-menu {
  width: 280px;
  margin-top: -90px;
  background: #006DB6;
  border-bottom: 4px solid #F8B427;
  font-family: "Oswald", sans-serif; }

.vertical-menu__item {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .vertical-menu__item.active .vertical-menu__link {
    color: #F8B427; }
  .vertical-menu__item.show-menu .vertical-menu__link {
    color: #F8B427; }
  .vertical-menu__item.show-menu .vertical-menu__sub {
    display: block; }

.vertical-menu__link {
  text-decoration: none;
  padding: 12px 15px;
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  transition: color 0.25s ease-in-out; }
  .vertical-menu__link:hover {
    color: #F8B427; }

.vertical-menu__sub {
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  display: none; }
  .vertical-menu__sub li {
    padding: 5px 15px; }
    .vertical-menu__sub li a {
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
      font-weight: 400;
      font-family: "Source Sans Pro", sans-serif;
      transition: color 0.25s ease-in-out; }
      .vertical-menu__sub li a:hover {
        color: #fff; }

@media (max-width: 1200px) {
  .menu__link {
    padding: 0 15px; } }

@media (max-width: 992px) {
  .home-slider {
    max-width: 100%; }
  .header .container {
    max-width: 100%; }
  .istasyon-btn {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.9rem; }
  .menu__link {
    font-size: 0.9rem;
    padding-left: 15px;
    padding-right: 15px; }
  .stations__text {
    font-size: 0.9rem; }
  .text--big {
    font-size: 1.1rem; }
  .about-section__img img {
    width: 100%; }
  .contact-info__item {
    margin-bottom: 15px;
    width: 100%; }
  .footer__bottom .container {
    justify-content: center; }
  .footer__bottom .social-footer {
    width: 100%;
    margin: 10px auto;
    text-align: center; }
  .stations__info .btn-light {
    margin-top: 15px; }
  .menu {
    display: none; }
  .btn-mobil {
    display: inline-block;
    margin-right: 15px; }
  .header--flex {
    height: 70px; }
  .istasyon-btn {
    height: 70px;
    line-height: 70px; }
  .logo {
    width: 145px; }
  .content-page .content {
    width: 100%;
    margin-bottom: 30px; }
  .left-menu {
    width: 100%;
    margin-top: 0; } }

.btn-mobil {
  padding: 0;
  position: relative;
  top: 4px;
  outline: transparent; }

.arka-active {
  display: block;
  opacity: 1; }

.mobil-nav--icon {
  display: block;
  height: 25px; }

.mobil {
  display: block;
  position: fixed;
  top: 0;
  left: -320px;
  background: #005188;
  color: #fff;
  width: 300px;
  height: 100%;
  max-width: calc(100% - 80px);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 9999; }
  .mobil strong {
    font-weight: 500;
    font-family: "Oswald", sans-serif; }
  .mobil .nav-tabs {
    display: flex;
    position: absolute;
    bottom: 0;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 99999; }
    .mobil .nav-tabs li {
      width: 50%;
      text-align: center; }
  .mobil .tab {
    overflow: hidden; }
  .mobil .iletisim-bilgileri {
    padding: 30px;
    line-height: 1.7; }
    .mobil .iletisim-bilgileri .iletisim-box {
      font-size: 0.85rem;
      padding-left: 0; }
    .mobil .iletisim-bilgileri span {
      opacity: 0.7; }
    .mobil .iletisim-bilgileri a {
      color: #fff;
      text-decoration: underline; }
  .mobil .tablinks {
    display: block;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 15px 0;
    padding-top: 18px;
    border-bottom: 3px solid transparent;
    opacity: 0.6;
    transition: all 0.3s; }
    .mobil .tablinks:hover {
      border-color: #F8B427;
      opacity: 0.8; }
    .mobil .tablinks.active {
      border-color: #F8B427;
      opacity: 1; }
  .mobil .tabcontent {
    padding-bottom: 45px;
    overflow-y: scroll;
    height: calc(100vh - 75px); }

.mobil-logo {
  padding: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .mobil-logo img {
    display: block;
    margin: 0 auto;
    width: 160px; }
  .mobil-logo .musteri-girisi {
    display: block;
    font-size: 14px;
    padding: 14px;
    margin: 0 auto;
    border-radius: 32px;
    width: 90%;
    text-decoration: none; }
    .mobil-logo .musteri-girisi i {
      right: 20px; }

.mobil-baslik {
  display: block;
  font-size: 16px;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 25px 15px;
  border: 0;
  transition: all 0.3s; }

.mobil-menu-li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .mobil-menu-li .btn-atik {
    display: block;
    padding: 15px 30px;
    width: auto;
    margin: 0 auto !important;
    width: 230px !important;
    margin-top: 30px !important; }

.mobil-ana-menu .mobil-menu-li a {
  width: 100%; }

.mobil-ana-menu .mobil-menu-li i {
  margin-top: 2px;
  float: right; }

.mobil-menu-link {
  display: block;
  font-size: 15px;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  color: #fff;
  padding: 20px 20px 20px 35px;
  transition: all 0.3s;
  text-decoration: none !important; }
  .mobil-menu-link i {
    margin-right: 6px; }
  .mobil-menu-link:focus, .mobil-menu-link:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #F8B427; }
  .mobil-menu-link.active {
    background: rgba(0, 0, 0, 0.1);
    color: #F8B427; }

.mobil-alt-menu {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  padding: 15px 0; }
  .mobil-alt-menu ul {
    padding: 10px 0;
    line-height: 1.4; }
  .mobil-alt-menu a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    padding: 12px 12px 12px 50px;
    text-decoration: none;
    transition: color 0.25s ease-in-out; }
    .mobil-alt-menu a:Hover {
      color: #F8B427; }
    .mobil-alt-menu a.active {
      color: #F8B427; }

.mobil-active {
  left: 0; }

.mobil-hr {
  background: rgba(255, 255, 255, 0.1);
  margin-left: -50px;
  border: 0;
  height: 1px; }

.mobil-tab {
  height: calc(100vh - 80px);
  overflow-y: scroll; }

@media (max-width: 768px) {
  .slider-detail {
    top: 120px; }
  .slider-detail__title {
    font-size: 2rem; }
  .slider-detail__text {
    font-size: 1.2rem; }
  .home-slider .item img {
    height: 500px; }
  .custom-arrow {
    width: 30px;
    height: 30px; }
  .custom-arrows {
    width: 63px; }
  .tab-nav--stations {
    bottom: auto;
    top: 15px; }
  .btn {
    font-size: 0.8rem; }
  .feature__text {
    padding: 75px 20px 20px;
    font-size: 1rem; }
  .hero,
  .home-slider {
    margin-top: -70px; }
  .hero {
    padding-top: 100px; }
    .hero__title {
      font-size: 1.4rem; }
  .station-img {
    width: 100%;
    float: none;
    margin-right: 0; }
  .slider-detail {
    padding: 0 15px; }
  .stations__info .btn-light {
    margin-top: 0; } }

@media (max-width: 576px) {
  .slider-detail__title {
    font-size: 1.6rem; }
  .slider-detail__text {
    font-size: 1rem;
    max-width: 100%; }
  .copyright {
    width: 100%;
    margin-bottom: 5px;
    text-align: center; }
  .footer-links {
    text-align: center;
    width: 100%; }
    .footer-links a {
      margin: 5px 5px 0; }
  .mt-5 {
    margin-top: 2rem !important; }
  .mb-5 {
    margin-bottom: 2rem !important; }
  .btn {
    padding-left: 1rem;
    padding-right: 1rem; }
  .home-gallery .custom-arrows {
    bottom: -65px;
    right: 0;
    left: 0;
    margin: 0 auto; }
  .title {
    font-size: inherit !important; }
  .text p, .text strong, .text span, .text b, .about-section__text p, .about-section__text strong, .about-section__text span, .about-section__text b {
    font-size: inherit !important; }
  .home-gallery-slider .item img {
    height: 150px; }
  .gallery-img {
    height: 150px; }
  .map iframe {
    height: 250px; } }

@media (max-width: 480px) {
  .istasyon-btn {
    display: none; } }
