@media (max-width: 768px) {


  .slider-detail {
    top: 120px;
  }

  .slider-detail__title {
    font-size: 2rem;
  }

  .slider-detail__text {
    font-size: 1.2rem;
  }

  .home-slider .item img {
    height: 500px;
  }

  .custom-arrow {
    width: 30px;
    height: 30px;
  }

  .custom-arrows {
    width: 63px;
  }

  .tab-nav--stations {
    bottom: auto;
    top: 15px;
  }

  .btn {
    font-size: 0.8rem;
  }

  .feature__text {
    padding: 75px 20px 20px;
    font-size: 1rem;
  }

  .hero,
  .home-slider {
    margin-top: -70px;
  }

  .hero {
    padding-top: 100px;

    &__title {
      font-size: 1.4rem;
    }
  }

  .station-img {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .slider-detail {
    padding: 0 15px;
  }

  .stations__info {
    .btn-light {
      margin-top: 0;
    }
  }
}
