@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'slick', 'fontawesome', 'toastr', 'typography', 'buttons';

.btn-mobil,
.mobil {
  display: none;
}

.arama-bg,
.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $renkss;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.arka-active {
  display: block;
}

.header {
  position: relative;
  z-index: 5;

  .container{
    position: relative;
  }

  &__right {
    @extend .flex;
    justify-content: flex-end;
    width: calc(100% - 200px);
  }
}

.header--flex {
  @extend .flex;
  height: 84px;
  border-bottom: 5px solid $renk_koyumavi;
  background: $renk_mavi;
}

.logo {
  padding: 10px 15px;
  width: 171px;
}

.menu {
  @extend .flex;
  font-weight: 500;
  font-family: $oswald;

  &__item {

    &:hover {
      > a {
        color: $renk_sari;
      }

      .mega-menu {
        display: flex;
      }
    }
  }

  &__link {
    @extend .efekt;
    position: relative;
    display: block;
    padding: 0 25px;
    height: 84px;
    color: #fff;
    text-decoration: none;
    line-height: 84px;

    &:after {
      @include efekt(height);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: $renk_sari;
      content: '';
    }

    &.active,
    &:hover {
      background: $renkss;

      &:after {
        height: 5px;
      }
    }
  }
}

.mega-menu{
  animation: fadeIn .5s;
  background: #004b7e;
  border-bottom: 4px solid $renk_sari;
  position: absolute;
  left: 15px;
  right: 15px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  z-index: 20;
}

.dropdown {
  padding: 5px 15px;
  width: 23%;

  &__title{
    color: $renk_sari;
    font-size: 1.1rem;
    display: block;
    border-bottom: 1px solid $renkss;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  li {
    display: block;

    &:last-child {
      border: none;
    }
  }

  a {
    text-decoration: none;
    display: block;
    padding: 5px;
    font-size: .9rem;
    font-family: $mont;
    color: #fff;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }
  }
}

.istasyon-btn {
  @extend .efekt;
  position: relative;
  padding: 0 25px;
  height: 84px;
  border-bottom: 5px solid $renk_sari_dk;
  background: $renk_sari;
  color: $renk_mavi;
  text-decoration: none;
  font-weight: 500;
  font-weight: 600;
  font-family: $oswald;
  line-height: 84px;

  &:hover {
    border-color: $renk_sari;
    background: $renk_koyumavi;
    color: $renk_sari;
  }
}

.btn--icon {
  svg {
    display: inline-block;
    margin-right: 8px;
  }
}

.home-slider {
  position: relative;
  z-index: 1;
  margin: -84px auto 0;

  .item {
    img {
      width: 100%;
      height: 670px;
      object-fit: cover;
    }
  }

  .slick-dots {
    bottom: 100px;
  }
}

.slick-dots {
  li {
    width: 10px;

    button {
      width: 8px;
      height: 8px;
      padding: 0;
      background: #fff;
      @include efekt(background);

      &:before {
        display: none;
      }

      &:hover {
        background: $renksb-4;
      }
    }
  }

  .slick-active {
    button {
      background: $renk_sari;
    }
  }
}

.home-gallery-slider {
  .item {
    margin: 5px 0;
  }

  .slick-dots {
    text-align: left;
    bottom: -35px;
    padding-left: 5px;

    li {
      button {
        background: $renk_koyumavi;

        &:hover {
          background: $renk_mavi;
        }
      }
    }

    .slick-active {
      button {
        background: $renk_sari;
      }
    }
  }

  .fresco {
    margin-right: 2px;
    margin-bottom: 2px;
    display: block;

    img {
      @extend .efekt;
      position: relative;
    }

    &:hover {
      img {
        cursor: zoom-in;
        z-index: 3;
        box-shadow: 0 0 0 2px $renk_sari;
      }
    }
  }
}

.slider-detail {
  position: absolute;
  top: 220px;
  text-align: center;
  width: 100%;

  &__title {
    color: #fff;
    font-size: 3rem;
    font-family: $oswald;
    font-weight: 600;
  }

  &__text {
    color: #fff;
    max-width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.4rem;
    font-weight: 300;
  }
}

.divider {
  display: block;
  margin: 15px auto;
  width: 9px;
  height: 9px;
  background: $renk_sari;
  position: relative;

  &--left {
    margin-left: 50px;
    margin-right: auto;
  }

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    height: 1px;
    background: $renk_sari;
    left: -52px;
    width: 45px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    height: 1px;
    background: $renk_sari;
    right: -52px;
    width: 45px;
  }
}

.stations {
  background: $renk_mavi;
  margin-top: -120px;
  position: relative;
  z-index: 2;
  padding: 15px 15px 0;

  &--page {
    margin-top: 0;
  }

  &__top {
    position: absolute;
    right: 0;
    top: -40px;
  }

  &__img {
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__info {
    color: #fff;
  }
}

.custom-arrows {
  @extend .flex;
  width: 86px;
}

.custom-arrow {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $renk_sari;
  color: $renk_mavi;
  @extend .efekt;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $renk_koyumavi;
    color: $renk_sari;
  }
}

.title-divider {
  display: block;
  width: 60px;
  height: 2px;
  margin: 20px 0;
  background: $renk_sari;

  &--left {
    margin-right: 0;
  }
}

.btn--icon--right {
  svg {
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-left: 6px;
  }
}

.tab-nav {
  &--stations {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 3;
  }

  .tab-link {
    display: inline-block;
    margin-right: 5px;
    background: #f9f9f9;
    padding: 7px 15px;
    border-bottom: 3px solid #dfdfdf;
    text-align: center;
    color: $renk_mavi;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: $oswald;
    text-decoration: none;
    @extend .efekt;

    &.active,
    &:hover {
      background: $renk_sari;
      border-color: $renk_sari_dk;
    }
  }
}

.home-features {
  margin: 70px 0;
}

.feature {
  text-align: center;

  &__img {
    max-width: 80%;
    display: block;
    margin: 0 auto -60px;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  }

  &__text {
    background: linear-gradient(100.48deg, #F8B427 0%, #D38F00 100%);
    padding: 80px 30px 30px;
    color: #fff;
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &--blue {
    .feature__text {
      background: linear-gradient(100.64deg, #006DB6 0%, #005188 98.67%);
    }
  }
}

.text {
  display: block;
  line-height: 1.8;

  &--big {
    font-size: 1.3rem;
    color: #fff;
  }
}

.about-section {
  &--reverse {
    .row {
      flex-direction: row-reverse;
    }
  }

  .row {
    align-items: center;
  }

  &__img {
    img {
      box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
    }
  }

  &__text {}
}

.home-gallery {
  position: relative;
  margin-bottom: 120px;

  .custom-arrows {
    position: absolute;
    bottom: -45px;
    right: 5px;
  }
}

.footer {
  &__top {
    background: #00508F;
    padding: 15px 0;
  }

  &__bottom {
    background: #004371;
    font-size: 0.9rem;
    padding: 15px 0;
    color: rgba(#ffffff, 0.7);

    .container {
      @extend .flex;
    }
  }
}

.copyright {}

.footer-links {
  a {
    text-decoration: none;
    color: rgba(#ffffff, 0.7);
    display: inline-block;
    margin-left: 15px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hero {
  margin: -84px auto 0;
  padding-top: 140px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: 4px solid $renk_sari;

  &--left {
    text-align: left;
  }

  &__title {
    font-size: 2rem;
    font-family: $oswald;
    margin-bottom: 15px;
    color: #fff;
  }
}

.breadcrumb {
  color: rgba(#ffffff, 0.82);
  font-size: 0.9rem;
  font-weight: 600;

  &__link {
    text-decoration: none;
    color: rgba(#ffffff, 0.82);

    &:hover {
      text-decoration: underline;
    }
  }

  &__divider {
    display: inline-block;
    margin: 0 6px;
  }

  &__item {}
}

.row--pad {
  margin-left: -5px;
  margin-right: -5px;
}

.col--pad {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;

  .fresco {
    img {
      @extend .efekt;
      position: relative;
    }

    &:hover {
      img {
        cursor: zoom-in;
        z-index: 3;
        box-shadow: 0 0 0 2px $renk_sari;
      }
    }
  }
}

.station-img {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 50%;
  border: 4px solid $renk_sari;
}

.map {
  border-top: 4px solid $renk_sari;

  iframe {
    vertical-align: middle;
    width: 100%;
  }
}

.contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin: 30px auto;

  &__item {
    width: 32%;
    padding: 30px;
    background: $renk_mavi;
    color: #fff;
  }

  &__icon {
    text-align: center;
    height: 60px;
    color: $renk_sari;

    svg {
      width: 45px;
      height: 45px;
    }
  }

  &__text {
    font-size: 16px;
    display: block;
    min-height: 57px;
  }

  &__title {
    display: block;
    font-weight: 800;
    font-size: 1.1rem;
    color: $renk_sari;
    margin-bottom: 15px;
    font-family: $oswald;
  }

  a {
    color: #fff;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }
  }
}

.social-footer {
  a {
    font-size: 1.4rem;
    display: inline-block;
    margin: 5px;
    color: #fff;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }
  }
}

.home-gallery-slider {
  .item img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

.gallery-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.home-gallery-slider .slick-dots li button {
  background: #fff;
}

.about-section__text,
.text,
.title,
.title--blue {
  color: #fff !important;
  background: inherit !important;
  background-color: inherit !important;

  b,
  p,
  span,
  strong {
    color: #fff !important;
    background: inherit !important;
    background-color: inherit !important;
  }
}

.kampanya {
  padding: 15px;
  background: $renkss;
  @include efekt(background);

  &__title {
    font-size: 1.3rem;
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #fff;
  }

  &__text {
    color: rgba(#ffffff, 0.8);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background: $renkss-2;
  }
}

.content-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 30px;

  .content {
    width: calc(100% - 310px);
  }
}

.left-menu {
  width: 280px;
  margin-top: -90px;
  background: $renk_mavi;
  border-bottom: 4px solid $renk_sari;
  @extend .bxs;
  font-family: $oswald;
  //
  // ul > li {
  //   display: block;
  //   border-bottom: 1px solid $renkss;
  //
  //   &:first-child {
  //     a {
  //       padding-top: 0;
  //     }
  //   }
  //
  //   &:last-child {
  //     border: none;
  //
  //     a {
  //       padding-bottom: 0;
  //     }
  //   }
  // }
  //
  // a {
  //   text-decoration: none;
  //   display: block;
  //   padding: 10px 0;
  //   color: #fff;
  //   @include efekt(color);
  //
  //   &:hover {
  //     color: $renk_sari;
  //   }
  // }
}

.vertical-menu {

	&__item {
    display: block;
    border-bottom: 1px solid $renkss;

    &.active{
      .vertical-menu__link{
        color: $renk_sari;
      }
    }

    &.show-menu{
      .vertical-menu__link{
        color: $renk_sari;
      }
      .vertical-menu__sub{
        display: block;
      }
    }
	}

	&__link {
    text-decoration: none;
    @extend .flex;
    padding: 12px 15px;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
    @include efekt(color);

    &:hover {
      color: $renk_sari;
    }
	}

	&__sub {
    background: $renkss;
    padding: 5px 0;
    display: none;
    li{
      padding: 5px 15px;
      a{
        color: rgba(#ffffff, 0.8);
        text-decoration: none;
        font-weight: 400;
        font-family: $mont;
        @include efekt(color);

        &:hover{
          color: #fff;
        }
      }
    }
	}

}


@import 'style1200', 'style992', 'mobil-nav', 'style768', 'style576';
