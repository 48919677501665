@media (max-width: 992px) {
  .home-slider {
    max-width: 100%;
  }

  .header {
    .container {
      max-width: 100%;
    }
  }

  .istasyon-btn {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.9rem;
  }

  .menu__link {
    font-size: 0.9rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  .stations__text {
    font-size: 0.9rem;
  }

  .text--big {
    font-size: 1.1rem;
  }

  .about-section__img{
    img{
      width: 100%;
    }
  }

  .contact-info__item{
    margin-bottom: 15px;
    width: 100%;
  }

  .footer__bottom{
    .container{
      justify-content: center;
    }

    .social-footer{
      width: 100%;
      margin: 10px auto;
      text-align: center;
    }
  }

  .stations__info{
    .btn-light{
      margin-top: 15px;
    }
  }

  .menu {
    display: none;
  }

  .btn-mobil {
    display: inline-block;
    margin-right: 15px;
  }

  .header--flex {
    height: 70px;
  }

  .istasyon-btn {
    height: 70px;
    line-height: 70px;
  }

  .logo {
    width: 145px;
  }

  .content-page{
    .content{
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .left-menu{
    width: 100%;
    margin-top: 0;
  }
}
