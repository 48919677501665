$renk_turuncu: #F07400;
$renk_mavi: #006DB6;
$renk_koyumavi: #005188;
$renk_gri: #687586;
$renk_yazi: #3E4651;
$renk_cizgi: #E0E0E0;
$renk_acik: #F2F5F9;
$renk_sari: #F8B427;
$renk_sari_dk: #D38F00;
$renkss: rgba(0, 0, 0, .1);
$renkss-2: rgba(0, 0, 0, .2);
$renkss-3: rgba(0, 0, 0, .3);
$renkss-4: rgba(0, 0, 0, .4);
$renksb: rgba(255, 255, 255, .1);
$renksb-2: rgba(255, 255, 255, .2);
$renksb-3: rgba(255, 255, 255, .3);
$renksb-4: rgba(255, 255, 255, .4);
$renksb-8: rgba(255, 255, 255, .8);
