@media (max-width: 576px) {
  .slider-detail__title {
    font-size: 1.6rem;
  }

  .slider-detail__text {
    font-size: 1rem;
    max-width: 100%;
  }

  .copyright {
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
  }

  .footer-links {
    text-align: center;
    width: 100%;

    a {
      margin: 5px 5px 0;
    }
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-gallery .custom-arrows {
    bottom: -65px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .title{
    font-size: inherit !important;
  }

  .text, .about-section__text{
    p, strong, span, b{
      font-size: inherit !important;
    }
  }

  .home-gallery-slider{
    .item img{
      height: 150px;
    }
  }

  .gallery-img{
    height: 150px;
  }

  .map{
    iframe{
      height: 250px;
    }
  }
}
@media (max-width: 480px) {
  .istasyon-btn {
    display: none;
  }
}
